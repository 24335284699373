import { useStore } from '@globalStore/storeProvider';
import { DateTimeFormatter } from '@utils/utils';

const FormHeader = () => {
  const { upcomingClass } = useStore();

  return (
    <div className="flex flex-col items-start">
      <p className="font-satoshi-bold text-[20px] leading-[30px] text-black">
        Book a {upcomingClass?.attributes.title || 'LIVE Demo Class'}
      </p>
      <p className="font-satoshi-regular text-[16px] leading-6 text-gray-navigation">
        Register to attend the Free LIVE class{' '}
        {upcomingClass && (
          <span className="text-[#1A1A1A]">
            @ {DateTimeFormatter(upcomingClass.attributes.scheduledAt, 'h:mm A', true)}
          </span>
        )}{' '}
      </p>
    </div>
  );
};

export default FormHeader;
