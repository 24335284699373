import axios from 'axios';

const userStackApiBaseUrl = process.env.NEXT_PUBLIC_ONBOARDING_ENDPOINT;

export async function requestRegistration({
  name,
  email,
  phone,
  graduationYear,
  userType,
  background,
  courseInterest,
}) {
  return axios
    .post(`${userStackApiBaseUrl}/api/website/demo-class/request-registration`, {
      name,
      email,
      phone,
      graduationYear,
      userType,
      background,
      courseInterest,
    })
    .then((res) => res.data);
}

export async function verifyRegistration({ referenceId, otp }) {
  return axios
    .post(`${userStackApiBaseUrl}/api/website/demo-class/verify-registration`, {
      referenceId,
      otp,
    })
    .then((res) => res.data);
}

export async function resendOtp({ referenceId, phone }) {
  return axios
    .post(`${userStackApiBaseUrl}/api/website/demo-class/resend-otp`, {
      referenceId,
      phone,
    })
    .then((res) => res.data);
}
