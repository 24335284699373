import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import InputField from '../../../../../stories/InputField';
import FormHeader from '@local/courses/forms/demo-class-form/FormHeader';
import { PhoneField } from '../../../../../stories/PhoneField';
import {
  requestRegistration,
  resendOtp,
  verifyRegistration,
} from '@local/courses/forms/demo-class-form/apiService';
import { AiFillCheckCircle } from 'react-icons/ai';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import { FaCalendarPlus } from 'react-icons/fa';
import { addToCalendar } from '@utils/utils';
import { globalFormFields, RCB_OPTION_LIST } from '@lib/constants';
import { HierarchyPrimary, Primary } from 'stories/Button.stories';
import { useStore } from '@globalStore/storeProvider';
import cn from 'classnames';
import { loginToAnalyticsSvc, setAttributesInAnalytics } from '@lib/analytics/analytics.service';
import { courseInterestMap } from '@components/shared/forms/ApplicationForm';
import { formValidation } from '@components/shared/forms/ApplicationForm/validation';
import GlobalForm from '@components/shared/forms/global-form/GlobalForm';
import Image from 'next/image';
import successGif from '@assets/gifs/success.gif';
import useStorage from '@utils/hooks/useStorage';

dayjs.extend(calendar);

function OnboardingDetailsForm({ onSubmitClicked, formLayout, buttonCTA }) {
  const { courseInterest } = useStore();
  const { getItemFromLocal } = useStorage();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    graduationYear: '',
    phone: '',
    termNCondition: false,
    background: undefined,
    userType: undefined,
    courseInterest: courseInterestMap[courseInterest] ?? courseInterest,
  });

  useEffect(() => {
    const localFormData = getItemFromLocal('rcb_form_data');

    if (!!localFormData) {
      const localStoredFormData = JSON.parse(localFormData);

      delete localStoredFormData['courseInterest'];

      setFormData({
        ...formData,
        ...localStoredFormData,
      });
    }
  }, []);

  const courseInterestOptions = useMemo(() => {
    return Object.entries(RCB_OPTION_LIST).reduce((accu, [key, value]) => {
      if (key !== 'web3') {
        accu.push({ label: value, value: key });
      }

      return accu;
    }, []);
  }, []);

  const changeHandler = (e) => {
    const { type, name, value, checked } = e.target;

    const val = type === 'checkbox' ? checked : value;

    if (name === 'graduationYear') {
      setFormData({
        ...formData,
        graduationYear: val,
        background: undefined,
        userType: undefined,
      });

      return;
    }

    setFormData({
      ...formData,
      [name]: val,
    });
  };

  const submitForm = async (updatedFormData) => {
    try {
      const { phone } = updatedFormData;

      await onSubmitClicked({
        ...updatedFormData,
        phone: `+91${phone}`,
      });
    } catch (err) {
      throw Error(err?.message);
    }
  };

  return (
    <GlobalForm
      formData={formData}
      changeHandler={changeHandler}
      formLayout={cn('flex flex-col gap-y-[16px]', formLayout)}
      onSubmit={submitForm}
      validate={formValidation}
      fields={globalFormFields(courseInterestOptions)}
      buttonCTA={buttonCTA || 'Continue'}
    />
  );
}

function ResendOtpForm({ referenceId, phone, resetForm }) {
  const [resendOtpForm, setResendOtpForm] = useState({
    phone: phone.replace('+91', ''),
  });
  const [resendOtpFormError, setResendOtpFormError] = useState({
    phone: null,
  });
  const [timeLeftForResend, setTimeLeftForResend] = useState(0);
  const [isResendOtpFormSubmitting, setIsResendOtpFormSubmitting] = useState(false);
  const [apiErrorMsg, setApiErrorMsg] = useState(null);
  const timerRef = useRef(null);

  useEffect(() => {
    startTimer();
    return () => {
      clearInterval(timerRef.current);
    };
  }, []);

  const validateResendOtpForm = useCallback((formData) => {
    const errors = {};
    if (!formData.phone) {
      errors.phone = 'Phone number is required';
    }
    if (formData.phone && String(formData.phone).length !== 10) {
      errors.phone = 'Phone number should be of 10 digits';
    }
    return errors;
  }, []);

  const startTimer = () => {
    let timeLeft = 30;
    timerRef.current = setInterval(() => {
      if (timeLeft > 0) {
        setTimeLeftForResend(timeLeft);
        timeLeft--;
      } else {
        setTimeLeftForResend(0);
        clearInterval(timerRef.current);
      }
    }, 1000);
  };

  const handleChangeResendOtpForm = (e) => {
    setResendOtpForm({ ...resendOtpForm, [e.target.name]: e.target.value });
  };

  const submitResendOtpForm = async (e) => {
    e.preventDefault();
    setIsResendOtpFormSubmitting(true);
    setApiErrorMsg(null);
    try {
      const errors = validateResendOtpForm(resendOtpForm);
      setResendOtpFormError(errors);
      if (Object.keys(errors).length > 0) {
        return;
      }
      await resendOtp({ referenceId, phone: `+91${resendOtpForm.phone}` });
      startTimer();
    } catch (error) {
      console.error(error);
      let message = 'Failed sending OTP';
      if (error.isAxiosError && error.response?.status === 500 && error.response.data?.message) {
        message = error.response.data.message;
        if (message.includes('Timed Out')) {
          resetForm();
        }
      }
      setApiErrorMsg(message);
    } finally {
      setIsResendOtpFormSubmitting(false);
    }
  };

  return (
    <form onSubmit={submitResendOtpForm}>
      <div className="flex flex-col gap-y-[6px]">
        <PhoneField
          label={'Phone Number'}
          labelStyle={'text-[14px] leading-5 font-satoshi-medium'}
          className="!rounded-lg !border !border-[#A3A3A3] !py-[8px] !px-[12px] !text-[16px] !leading-6"
          value={resendOtpForm.phone}
          name="phone"
          placeholder="Enter Mobile Number"
          onChange={handleChangeResendOtpForm}
          error={resendOtpFormError.phone}
        />

        <HierarchyPrimary
          label={timeLeftForResend > 0 ? `${timeLeftForResend} seconds` : 'Resend OTP'}
          type="submit"
          size="small"
          primary={false}
          customStyle="text-[#646464] disabled:text-[#646464] self-end text-[12px] font-satoshi-regular leading-[18px]"
          disabled={isResendOtpFormSubmitting || timeLeftForResend > 0}
        />
      </div>
      {!!apiErrorMsg && (
        <p className="ml-1 mt-1 text-center text-xs text-red md:text-sm">{apiErrorMsg}</p>
      )}
    </form>
  );
}

function OtpForm({ onSubmitClicked, referenceId, phone, resetForm }) {
  const [otpForm, setOtpForm] = useState({
    otp: '',
  });
  const [otpFormError, setOtpFormError] = useState({
    otp: null,
  });
  const [isVerifyOtpFormSubmitting, setIsVerifyOtpFormSubmitting] = useState(false);
  const [apiErrorMsg, setApiErrorMsg] = useState(null);

  const handleChangeOTPForm = (e) => {
    setOtpForm({ ...otpForm, [e.target.name]: e.target.value });
  };
  const validateSubmitForm = useCallback((formData) => {
    const errors = {};
    if (!formData.otp) {
      errors.otp = 'OTP is required';
    }
    if (formData.otp && formData.otp.length !== 4) {
      errors.otp = 'OTP should be of 4 digits';
    }
    return errors;
  }, []);

  const resetFormInSomeTime = () => {
    setTimeout(() => {
      resetForm();
    }, 2000);
  };
  const submitForm = async (e) => {
    setIsVerifyOtpFormSubmitting(true);
    setApiErrorMsg(null);
    try {
      e.preventDefault();
      const errors = validateSubmitForm(otpForm);
      setOtpFormError(errors);
      if (Object.keys(errors).length > 0) {
        return;
      }
      await onSubmitClicked(otpForm);
    } catch (error) {
      console.error(error);
      let message = 'Failed verifying OTP';
      if (error.isAxiosError && error.response?.status === 500 && error.response.data?.message) {
        message = error.response.data.message;
        if (message.includes('OTP expired')) {
          resetFormInSomeTime();
        }
      }
      setApiErrorMsg(message);
    } finally {
      setIsVerifyOtpFormSubmitting(false);
    }
  };

  return (
    <div className="flex flex-col gap-y-[16px]">
      <ResendOtpForm phone={phone} referenceId={referenceId} resetForm={resetFormInSomeTime} />
      <form onSubmit={submitForm} className="flex flex-col gap-y-[24px]">
        <InputField
          type="text"
          value={otpForm.otp}
          name="otp"
          error={otpFormError.otp}
          onChange={handleChangeOTPForm}
          placeholder="Enter Your OTP"
          inputStyle="!rounded-lg !border !border-[#A3A3A3] !py-[8px] !px-[12px] !text-[16px] !leading-6 placeholder:text-gray-400"
        />
        <Primary
          type="submit"
          disabled={isVerifyOtpFormSubmitting}
          label="Register for Demo Class"
          size="large"
          customStyle="w-full"
        />
        {!!apiErrorMsg && (
          <p className="ml-1 mt-1 text-center text-xs text-red md:text-sm">{apiErrorMsg}</p>
        )}
      </form>
    </div>
  );
}

function RegisteredClassMessage({ registeredClass }) {
  const time = dayjs(registeredClass.attributes.scheduledAt).calendar(dayjs());
  const addToCalendarFunc = () => {
    addToCalendar({
      topic: registeredClass.attributes.title,
      startTime: registeredClass.attributes.scheduledAt,
      endTime:
        registeredClass.attributes.endTime ||
        dayjs(registeredClass.attributes.scheduledAt).add(1, 'hour').toISOString(),
      details: registeredClass.attributes.description,
    });
  };

  return (
    <div className="inline font-satoshi-medium text-[18px] leading-7 text-black">
      You have successfully registered for the Demo class that will start{' '}
      <span className="cursor-pointer text-red" onClick={addToCalendarFunc}>
        {time} <FaCalendarPlus className="inline align-baseline" />
      </span>
      . You can also join the class at the scheduled time using the Classroom tab in your dashboard
    </div>
  );
}

function SuccessMessage({ registeredClass }) {
  return (
    <div className="flex flex-col items-center gap-y-[16px]">
      <div className="flex flex-col items-center gap-y-[4px]">
        <Image src={successGif} alt="registrationSuccess" height={100} width={100} loading="lazy" />
        <p className="font-satoshi-bold text-[24px] leading-[30px] text-black">
          Thank you for Interest!
        </p>
      </div>

      {!!registeredClass ? (
        <RegisteredClassMessage registeredClass={registeredClass} />
      ) : (
        <span className="font-satoshi-medium text-[18px] leading-7">
          You have successfully registered to attend our Online classes.
        </span>
      )}

      <div className="flex flex-col gap-y-[16px] rounded-lg bg-red-light-1 p-[24px]">
        <p className="font-satoshi-medium text-[16px] leading-6 text-black">
          <span className="text-">🚀</span> In the meantime, sign up and take a free preview of your
          Class!
        </p>
        <Primary
          linkCTA
          href={`${process.env.NEXT_PUBLIC_GROW_DOMAIN}/data-science/classroom`}
          label="Preview your Class"
          size={'large'}
        />
      </div>
    </div>

    // <div className="rounded-lg bg-white p-[24px]">
    //   <div className="flex flex-col items-center gap-y-[16px]">
    //     <AiFillCheckCircle className="text-[#079455]" size={48} />

    //     <div className="flex flex-col gap-y-[4px]">
    //       <p className="text-center font-satoshi-bold text-[20px] leading-[30px]">
    //       </p>

    //       <div className="text-center font-satoshi-medium text-[12px] leading-[18px] text-gray-400">

    //       </div>
    //     </div>

    //   </div>
    // </div>
  );
}

export default function DemoClassForm({ onBoardingFormStyle, formLayout, container }) {
  const [requestRegistrationResponseWithPhone, setRequestRegistrationResponseWithPhone] =
    useState();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [classRegistered, setClassRegistered] = useState();

  const requestRegistrationCallback = async (data) => {
    try {
      setAttributesInAnalytics(data.name, data.email, data.phone);
      const response = await requestRegistration(data);

      if (!!response.userId) {
        loginToAnalyticsSvc(response.userId);
      }

      setRequestRegistrationResponseWithPhone({ ...response, phone: data.phone });
    } catch (e) {
      console.error(e);
    }
  };

  const verifyRegistrationCallback = async (data) => {
    const { otp } = data;
    const response = await verifyRegistration({
      referenceId: requestRegistrationResponseWithPhone.referenceId,
      otp,
    });
    if (response.registeredClass) {
      setClassRegistered(response.registeredClass);
      setShowSuccessMessage(true);
    }
  };

  const resetForm = () => {
    setRequestRegistrationResponseWithPhone(null);
  };

  useEffect(() => {
    sessionStorage.setItem('demo_class', true)

    return () => sessionStorage.removeItem('demo_class')
  }, [])


  return (
    <div className={cn('flex flex-col gap-y-[16px] rounded-lg bg-white p-[24px] pr-0', container)}>
      <FormHeader />
      <div className={cn('pr-[24px]', onBoardingFormStyle)}>
        {!requestRegistrationResponseWithPhone && (
          <OnboardingDetailsForm
            onSubmitClicked={requestRegistrationCallback}
            buttonCTA={'Book your Demo Class'}
            formLayout={formLayout}
          />
        )}
        {!!(requestRegistrationResponseWithPhone && !showSuccessMessage) && (
          <OtpForm
            referenceId={requestRegistrationResponseWithPhone.referenceId}
            phone={requestRegistrationResponseWithPhone.phone}
            onSubmitClicked={verifyRegistrationCallback}
            resetForm={resetForm}
          />
        )}
        {showSuccessMessage && <SuccessMessage registeredClass={classRegistered} />}
      </div>
    </div>
  );
}
